import { fetchUserContext } from '@features/context/thunks/fetchUserContext'
import { fetchContract } from '@features/contract/thunks/fetchContract'
import { fetchEntitledOrganization } from '@features/organization/thunks/fetchEntitledOrganization'
import { sendRegistrationEvent, sendRegisterErrorEvent } from '@foundation/analytics/tealium/lib'
import personService from '@foundation/apis/transaction/person.service'
import { createAsyncThunk } from '@reduxjs/toolkit'
import { IPayloadBasePromoCode } from '@typesApp/common'
import { setUserDetails, setUserLoggedIn } from '../slice'
import { TFunction } from 'i18next'

export type IRegistrationArgs = IPayloadBasePromoCode & {
  body: {
    firstName: string
    lastName: string
    logonId: string
    logonPassword: string
    logonPasswordVerify: string
    registerType: string
    profileType: string
    email1: string
    dateOfBirth: string
    phone1: string
    storeId: string
    catalogId: string
    preferredLanguage: string
    preferredCurrency: string
    receiveEmail: string
    receiveEmailPreference: [
      {
        value: boolean
        storeID: string
      },
    ]
    challengeQuestion: string
    challengeAnswer: string
    x_optIn: {
      x_privacy: boolean
      x_newsLetter: boolean
      x_profiling: boolean
    }
  }
  translation: TFunction
}

export const registration = createAsyncThunk<any, IRegistrationArgs>(
  'user/registration',
  async (args, { dispatch, rejectWithValue }) => {
    try {
      const response = await personService.registerPerson(args)
      const registrationPayload = response.data
      if (args?.widget) {
        registrationPayload['widget'] = args.widget
      }
      dispatch(setUserLoggedIn(registrationPayload))
      dispatch(fetchContract(registrationPayload))
      dispatch(fetchEntitledOrganization(registrationPayload))
      dispatch(fetchUserContext(registrationPayload))
      const acceptedNewsletterFlag = args?.body?.x_optIn?.x_newsLetter || false

      const response2 = await personService.findPersonBySelf({
        widget: args.widget,
        storeId: args.storeId,
      })
      const registrationPayload2 = response2.data
      sendRegistrationEvent(registrationPayload2, acceptedNewsletterFlag)

      dispatch(setUserDetails(registrationPayload2))

      return registrationPayload
    } catch (e) {
      sendRegisterErrorEvent(args.translation)
      // TODO: remove after error rtk thunk done
      // yield put({ type: ACTIONS.REGISTRATION_ERROR, error })
      return rejectWithValue(e)
    }
  }
)
