import { createAsyncThunk } from '@reduxjs/toolkit'
import { ContactLensPayload } from '@typesApp/prescription'
import { IProduct } from '@typesApp/product'
import { ORDER_CONFIGS } from '../../../configs/order'
import { SUCCESS_MSG_PREFIX } from '../../../constants/common'
import { ORDER_EXTEND_ATTRIBUTE_NAMES } from '../../../constants/order'
import { CART } from '../../../constants/routes'
import { sendAddToCartClickEvent } from '../../../foundation/analytics/tealium/lib'
import cartService from '../../../foundation/apis/transaction/cart.service'
import { toggleAddContactLensesToCartError } from '../../product/slice'
import { sendSuccessMessage } from '../../success/slice'

type CLItem = {
  x_contactLens: ContactLensPayload
  quantity: string
}
export type ContactLensesAddItemArgs = {
  contractId?: string
  callback?: any
  product?: IProduct
  updateCart?: boolean
  langId: string
  items: CLItem | {}[] | null
  customerSegments?: string[]
}

const addContactLenses = createAsyncThunk<any, ContactLensesAddItemArgs>(
  'order/addContactLensItem',
  async (args, { dispatch, rejectWithValue }) => {
    try {
      const payload = args
      const addToCartSuccessCallback = args.callback

      const _orderExtendAttributes = {
        attributeName: ORDER_EXTEND_ATTRIBUTE_NAMES.LANG_ID,
        attributeType: 'string',
        attributeValue: payload.langId,
      }
      const body = {
        body: {
          orderId: '.',
          x_calculateOrder: ORDER_CONFIGS.calculateOrder,
          orderItem: args.items,
          x_inventoryValidation: ORDER_CONFIGS.inventoryValidation,
          orderExtendAttribute: [_orderExtendAttributes],
          x_calculationUsage: ORDER_CONFIGS.calculationUsage,
        },
        skipErrorSnackbar: true,
      }

      const response = await cartService.addOrderItem(body)

      const items = (args.items && Array.isArray(args.items) ? args.items : []) as CLItem[]
      const quantity = items.reduce((acc: number, currentValue) => {
        return acc + parseInt(currentValue.quantity as string)
      }, 0)
      const productsWithQuantity = payload.product
        ? [
            {
              ...payload.product,
              quantity: `${quantity}`,
              id: payload.product.uniqueID,
            },
          ]
        : []

      sendAddToCartClickEvent(productsWithQuantity, args.customerSegments)
      dispatch(
        sendSuccessMessage({
          key: SUCCESS_MSG_PREFIX + 'ITEM_ADD_SUCCESS',
          link: {
            url: CART,
            textKey: SUCCESS_MSG_PREFIX + 'ViewCart',
          },
        })
      )

      addToCartSuccessCallback?.()

      return response.data
    } catch (error) {
      dispatch(toggleAddContactLensesToCartError(true))
      return rejectWithValue(error)
    }
  }
)

export default addContactLenses
