import config from '@configs/index'
export const site = {
  storeName: config?.name || '',
  country: config?.defaultCountry || '',
  transactionContextUrl: `${config?.transactionHost}${config?.transactionContext}` || '',
  transactionContext: config?.transactionContext || '',
  searchContext: config?.searchContext || '',
  searchContextUrl: `${config?.transactionHost}${config?.searchContext}` || '',
  searchContextUrlInternal: `${config?.transactionHostInternal}${config?.searchContext}` || '',
  cmcPath: '/lobtools/cmc/ManagementCenterMain',
}

export const STORES = {
  'it-it': {
    storeId: '80206',
    langId: '-4',
    country: 'it',
    catalogId: '80201',
    homepagePath: '/',
  },
  'fr-fr': {
    storeId: '80204',
    langId: '-2',
    country: 'fr',
    catalogId: '80201',
    homepagePath: '/',
  },
  'en-ie': {
    storeId: '80205',
    langId: '-45',
    country: 'ie',
    catalogId: '80201',
    homepagePath: '/',
  },
  'en-ww': {
    storeId: '80999',
    langId: '-99',
    country: 'ww',
    catalogId: 'catalogID',
    homepagePath: '/',
  },
  'es-es': {
    storeId: '80203',
    langId: '-5',
    country: 'es',
    catalogId: '80201',
    homepagePath: '/',
  },
  'nl-nl': {
    storeId: '80207',
    langId: '-44',
    country: 'nl',
    catalogId: '80201',
    homepagePath: '/',
  },
  'en-uk': {
    storeId: '80201',
    langId: '-43',
    country: 'uk',
    catalogId: '80201',
    homepagePath: '/',
  },
  'fr-be': {
    storeId: '80202',
    langId: '-40',
    country: 'be',
    catalogId: '80201',
    homepagePath: '/fr',
  },
  'nl-be': {
    storeId: '80202',
    langId: '-41',
    country: 'be',
    catalogId: '80201',
    homepagePath: '/nl',
  },
}
