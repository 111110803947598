import { fetchUserContext } from '@features/context/thunks/fetchUserContext'
import { fetchContract } from '@features/contract/thunks/fetchContract'
import { fetchEntitledOrganization } from '@features/organization/thunks/fetchEntitledOrganization'
import loginIdentityService from '@foundation/apis/transaction/loginIdentity.service'
import { createAsyncThunk } from '@reduxjs/toolkit'
import Log from '@services/Log'
import { IPayloadBasePromoCode } from '@typesApp/common'
import { clearUser } from '../slice'
import { resetCheckout } from '@redux/slices/checkout'
import router from 'next/router'
import { SIGNIN } from '@constants/routes'

/**
 * Redirect back to direct billing UI if session expired while user was
 * on the direct billing flow.
 */
const getSearchParam = (): string => {
  const search = location.search ?? ''
  const insuranceRedirect = sessionStorage.getItem('direct-billing.loginRedirect')
  if (insuranceRedirect) {
    return `${search}${search ? '&' : '?'}redirect=${insuranceRedirect}`
  } else {
    return search
  }
}

export type ILogoutArgs = IPayloadBasePromoCode

export const logout = createAsyncThunk<any, ILogoutArgs>('user/logout', async (args, { dispatch, rejectWithValue }) => {
  const clearState = () => {
    dispatch(clearUser())
    dispatch(fetchContract(args))
    dispatch(fetchEntitledOrganization(args))
    dispatch(fetchUserContext(args))
    dispatch(resetCheckout())
  }
  try {
    await loginIdentityService.logout(args)
    clearState()
    const search = getSearchParam()
    const redirectionUrl = args?.redirectTo ?? `/${SIGNIN}${search}`

    router.push(redirectionUrl)
    return
  } catch (e) {
    Log.error('error: ' + e)
    //still need to clear user token, event though logout fail to avoid infinite loop
    clearState()
    return rejectWithValue(e)
  }
})
