var _sentryCollisionFreeGlobalObject = typeof window != "undefined" ? window : typeof global != "undefined" ? global : typeof self != "undefined" ? self : {};
_sentryCollisionFreeGlobalObject["__sentryRewritesTunnelPath__"] = undefined;
_sentryCollisionFreeGlobalObject["SENTRY_RELEASE"] = {"id":"vde_NIT-9525-soft-login_3f9b5b_b35_2059031024"};
_sentryCollisionFreeGlobalObject["__sentryBasePath"] = undefined;
_sentryCollisionFreeGlobalObject["__rewriteFramesAssetPrefixPath__"] = "";

// This file configures the initialization of Sentry on the client.
// The config you add here will be used whenever a users loads a page in their browser.
// https://docs.sentry.io/platforms/javascript/guides/nextjs/

import * as Sentry from '@sentry/nextjs'
import domainsList from './configuration/domains/domains-list.json'

Sentry.init({
  dsn: process.env.NEXT_PUBLIC_SENTRY_DSN || process.env.SENTRY_DSN,
  debug: false,
  release: process.env.NEXT_PUBLIC_RELEASE_VERSION,
  environment: process.env.SENTRY_ENV || process.env.NEXT_PUBLIC_SENTRY_ENV,
  beforeSend(event) {
    // Add custom information to the event
    const url = event?.request?.url
    const currentDomain = domainsList.find(domainItem => url?.indexOf(domainItem.domain) !== -1)
    const currentLocale = currentDomain?.defaultLocale

    event.tags = {
      ...event.tags,
      locale: currentLocale || process.env.DEFAULT_LOCALE,
      isClientError: true,
      productionEnv: process.env.NEXT_PUBLIC_PROD_ENV,
    }
    return event
  },
  tracesSampleRate: Number(process.env.TRACES_SAMPLE_RATE || process.env.NEXT_PUBLIC_TRACES_SAMPLE_RATE) || 0,
  replaysSessionSampleRate: 0.0, // Do not record regular session replays
  replaysOnErrorSampleRate: 1.0, // Record 100% of sessions with errors
})
