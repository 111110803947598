import { TPriceServer } from '@components/common/UI/ProductPrice/index.types'
import { Attachment } from '@typesApp/product'

// New Product unified type
type TProductPrices = {
  [key: string]: TPriceServer
}

export type TProduct = {
  productId: string
  attachments?: Attachment[]
  attributes: Record<string, string>
  attributes_translated: Record<string, string>
  prices: TProductPrices
  price?: TProductPrices
  buyable: boolean
  display?: boolean
  objectID?: string
  parentProductId?: string
  partnumberId: string
  storeId?: string
  sort?: Record<string, Record<string, string | number>>
  sKUs?: TProduct[]
  cluster?: TProduct[]
  url?: string
  x_groupkey?: string
  catalogEntryTypeCode?: string
  items?: TProduct[]
  massoc?: Record<string, any>
  longDescription?: string
}

export type TLightProduct = Pick<TProduct, 'prices' | 'attachments' | 'attributes' | 'buyable' | 'url' | 'partnumberId'>

// Algolia Types
type TAlgoliaAttachments = {
  id: string
  identifier: string
  name: string
  rule: string
  sequence: number
  store: string
  url: string
}

type TAlgoliaPrices = {
  [key: string]: TPriceServer
}

export type TAlgoliaProductRes = {
  attachments: TAlgoliaAttachments[]
  attributes: Record<string, string>
  attributes_translated: Record<string, string>
  buyable: boolean
  catalogId: string
  categories: string[]
  categories_translated: string[]
  categories_tree: Record<string, string[]>
  categories_tree_ids: string[]
  categories_tree_translated: Record<string, string[]>
  category_ids: string[]
  display: boolean
  languageId: string
  languageLocale: string
  massoc: Record<string, any>
  modifiedTime: string
  objectID: string
  parentPartnumberId: string
  parentProductId: string
  partnumberId: string
  prices: TAlgoliaPrices
  productId: string
  sort: Record<string, Record<string, string | number>>
  sortDiscount_EarlyAccess: number
  sortDiscount_Guest: number
  sortDiscount_RegisteredUsers: number
  sortPrice_EarlyAccess: number
  sortPrice_Guest: number
  sortPrice_RegisteredUsers: number
  storeId: string
  storeToken: string[]
  type: string
  url: string
  x_groupkey: string
  cluster?: TAlgoliaProductRes[]
  items?: TAlgoliaProductRes[]
}

// HCL Types
type THclAttachments = {
  attachmentAssetPathRaw: string
  identifier: string
  sequence: string
  usage: string
}

type THclProductPrices = {
  [key: string]: TPriceServer
}

type THclProductAttributes = {
  identifier: string
  values: [
    {
      sequence: string
      identifier: string
      value: string
      uniqueID: string
      unitOfMeasure?: string
      unitID?: string
    },
  ]
}

export type THclProductRes = {
  attachments?: THclAttachments[]
  buyable: boolean
  catalogEntryTypeCode?: string
  cluster?: THclProductRes[]
  hasSingleSKU: boolean
  name: string
  numberOfSKUs: number
  parentCatalogGroupID: string
  partNumber: string
  productAttributes: Record<string, string>
  attributes?: THclProductAttributes[]
  'relationship.item.id'?: string
  'relationship.item.sequence'?: string
  parentCatalogEntryID?: string
  resourceId: string
  sKUs?: THclProductRes[]
  items?: THclProductRes[]
  storeID: string
  uniqueID: string
  x_groupkey: string
  x_price: THclProductPrices
  price: THclProductPrices
  _ignored: string
  seo?: { href: string }
  longDescription?: string
  displayable?: string
}

export type TLightProductRes = Pick<
  THclProductRes,
  'attachments' | 'longDescription' | 'productAttributes' | 'buyable' | 'seo' | 'partNumber'
> & {
  prices: THclProductRes['x_price']
  displayable: boolean
}

// Algolia DTO
export const algoliaProductPricesDTO = (prices: TAlgoliaPrices): TProductPrices => {
  return prices
}

const algoliaProductAttachmentsDTO = (attachments: TAlgoliaAttachments): Attachment => {
  return {
    identifier: attachments.identifier,
    usage: attachments.rule,
    sequence: `${attachments.sequence.toString()}.0`,
    attachmentAssetPathRaw: attachments.url,
    name: attachments.name,
  }
}

export const algoliaDTO = (product: TAlgoliaProductRes): TProduct => {
  return {
    attachments: product?.attachments?.map(algoliaProductAttachmentsDTO),
    prices: algoliaProductPricesDTO(product.prices),
    attributes: product.attributes,
    attributes_translated: product.attributes_translated,
    buyable: product.buyable,
    display: product.display,
    objectID: product?.objectID,
    parentProductId: product?.parentProductId,
    partnumberId: product.partnumberId,
    storeId: product.storeId,
    sort: product.sort,
    url: product.url,
    x_groupkey: product.x_groupkey,
    catalogEntryTypeCode: product.type,
    productId: product.productId,
    cluster: product?.cluster?.map(algoliaDTO),
    items: product?.items?.map(algoliaDTO),
    massoc: product.massoc,
  }
}

// HCL DTO
const hclProductAttachmentsDTO = (attachments: THclAttachments): Attachment => {
  return {
    identifier: attachments.identifier,
    usage: attachments.usage,
    sequence: attachments.sequence,
    attachmentAssetPathRaw: attachments.attachmentAssetPathRaw,
    name: '',
  }
}

const hclProductPricesDTO = (prices: THclProductPrices): TProductPrices => {
  return prices
}

const isHclProductRes = (product: THclProductRes | TLightProductRes): product is THclProductRes => {
  return product !== null && ('sKUs' in product || 'items' in product)
}

const hclProductUrlDTO = (product: THclProductRes | TLightProductRes) => {
  if (product?.seo) return product.seo?.href
  if (isHclProductRes(product)) {
    return product !== null && (product.sKUs ? product.sKUs?.[0]?.seo?.href : product.items?.[0]?.seo?.href)
  }
  return ''
}

const hclProductAttributes = (attributes: THclProductAttributes[] = []): Record<string, string> => {
  return attributes.reduce((acc: Record<string, string>, attr: THclProductAttributes) => {
    const value = attr?.values?.[0]?.value || ''
    return {
      ...acc,
      [attr.identifier]: value,
    }
  }, {})
}

export const hclDTO = (product: THclProductRes): TProduct => {
  return {
    attachments: product?.attachments?.map(hclProductAttachmentsDTO),
    prices: hclProductPricesDTO(product?.x_price),
    price: product?.price,
    attributes: product?.productAttributes || hclProductAttributes(product?.attributes),
    attributes_translated: product?.productAttributes || hclProductAttributes(product?.attributes),
    buyable: product?.buyable,
    parentProductId: (product && product['relationship.item.id']) || product?.parentCatalogEntryID,
    partnumberId: product?.partNumber,
    productId: product?.uniqueID,
    storeId: product?.storeID,
    url: hclProductUrlDTO(product) || '',
    x_groupkey: product?.x_groupkey,
    catalogEntryTypeCode: product?.catalogEntryTypeCode,
    sKUs: product?.sKUs?.map(hclDTO),
    cluster: product?.cluster?.map(hclDTO),
    items: product?.items?.map(hclDTO),
    longDescription: product?.longDescription,
  }
}

export const lightHclDTO = (product: TLightProductRes): TLightProduct => {
  return {
    attachments: product?.attachments?.map(hclProductAttachmentsDTO),
    prices: hclProductPricesDTO(product.prices),
    attributes: product.productAttributes,
    buyable: product.buyable,
    partnumberId: product.partNumber,
    url: hclProductUrlDTO(product) || '',
  }
}
